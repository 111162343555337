import 'swiper/swiper-bundle.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  Move,
  Tag,
  Users,
} from 'react-feather'
import SwiperCore, { Autoplay, EffectFade, Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay, EffectFade, Lazy, Navigation])

export interface Props {
  claim?: string
  claimLabel?: string
  pax?: string
  pricefrom?: string
  size?: string
  slides?: ImageProps[]
  validity?: string
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  claimLabel,
  pax,
  pricefrom,
  size,
  slides,
  validity,
  variant = 'default',
}: Props) {
  return (
    <Container tag="section" variant={variant}>
      {claim ? (
        <Claim className="hero-claim">
          <Title>{claim}</Title>
          {claimLabel ? <Label>{claimLabel}</Label> : null}
        </Claim>
      ) : null}
      {size || pax || pricefrom || validity ? (
        <InfoWrap dial={5} row>
          {pax ? (
            <Pax dial={5} row>
              <Users />
              {pax}
            </Pax>
          ) : null}
          {size ? (
            <Size dial={5} row>
              <Move />
              {size}
            </Size>
          ) : null}
          {pricefrom ? (
            <Pricefrom dial={5} row>
              <Tag />
              {pricefrom}
            </Pricefrom>
          ) : null}
          {validity ? (
            <Validity dial={5} row>
              <Calendar />
              {validity}
            </Validity>
          ) : null}
        </InfoWrap>
      ) : null}
      <Slider>
        {slides ? (
          <>
            <Swiper
              allowTouchMove={false}
              autoplay={{ delay: 4000 }}
              effect="fade"
              lazy
              loop
              navigation={{
                nextEl: `.hero-next`,
                prevEl: `.hero-prev`,
              }}
              preloadImages={false}
            >
              {uniq(slides).map((item, index) => (
                <SwiperSlide key={index}>
                  <Image
                    className="swiper-lazy"
                    src={item.src}
                    srcSet={item.srcSet}
                    alt={item.alt}
                    width={item.width}
                    height={item.height}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {slides.length > 1 ? (
              <SliderControls className="hero-slider-controls" row>
                <Arrow dial={5} className={`hero-prev`}>
                  <ChevronLeft />
                </Arrow>
                <Arrow dial={5} className={`hero-next`}>
                  <ChevronRight />
                </Arrow>
              </SliderControls>
            ) : null}
          </>
        ) : null}
      </Slider>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 66vh;
        `
    }
  }}
`

const Claim = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;
`

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3.375rem;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-top: 0.75rem;
  text-transform: uppercase;
`

const InfoWrap = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.75rem;
  padding: 1.6875rem 2.25rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  text-transform: uppercase;
  transform: translateX(-50%);
  > div {
    margin-left: 1.875rem;
    &:first-of-type {
      margin-left: 0;
    }

    @media (max-width: 1023px) {
      margin-left: 1.5rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
  svg {
    width: auto;
    height: 1.875rem;
    margin-right: 1rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1.5;
  }

  @media (max-width: 1023px) {
    padding: 1.25rem 1.5rem;
    svg {
      height: 1.625rem;
    }
  }

  @media (max-width: 767px) {
    right: 0;
    left: 0;
    transform: none;
  }
`

const Pax = styled(FlexBox)``

const Size = styled(FlexBox)``

const Pricefrom = styled(FlexBox)``

const Validity = styled(FlexBox)``

const Slider = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;

  > .swiper-container {
    height: 100%;
    &:before,
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &:before {
      background: ${({ theme }) => theme.colors.variants.neutralDark1};
      height: 100%;
      opacity: 0.36;
    }
    &:after {
      background: linear-gradient(
        ${rgba(theme.colors.variants.neutralDark1, 0.6)},
        ${rgba(theme.colors.variants.neutralDark1, 0)}
      );
      height: 30%;
    }

    .swiper-slide {
      height: 100%;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`

const SliderControls = styled(FlexBox)`
  position: absolute;
  bottom: 5.25rem;
  right: 4.625rem;
  z-index: 2;

  @media (max-width: 767px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin-left: 3.125rem;
  overflow: hidden;
  position: relative;
  svg {
    width: auto;
    height: 5.375rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 0.4;
    position: absolute;
    top: -1.25rem;
    left: -1.25rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
